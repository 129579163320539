import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App.vue'
import http from './plugins/http.js'
import router from './router/index'
import store from './store/index'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css' // 响应式

import QuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'
// import ECharts from 'vue-echarts'
// import 'echarts/lib/chart/line'
// Vue.component('echart', ECharts)
let pkg = require('../package.json')

import Print from 'vue-print-nb'
import components from './components'

import VueClipboards from 'vue-clipboards'
Vue.use(VueClipboards)

Vue.use(components)


//import './mock'

/**
 * 日期格式化
 */
Date.prototype.format = function (format) {
  let o = {
    "M+": this.getMonth() + 1, //month
    "d+": this.getDate(), //day
    "h+": this.getHours(), //hour
    "m+": this.getMinutes(), //minute
    "s+": this.getSeconds(), //second
    "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
    "S": this.getMilliseconds() //millisecond
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }

  for (let k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    }
  }
  return format;
}


Vue.use(Print);  //注册
Vue.use(QuillEditor)
Vue.use(ElementUI);

Vue.prototype.version = window.version
Vue.prototype.$http = http
Vue.prototype.$isElectron = window && window.process && window.process.versions && window.process.versions['electron']

Vue.prototype.$pkg = pkg

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
