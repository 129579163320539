import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    // mode: 'history',
    routes: [
        {path: '/', redirect: '/login'},
        {path: '/update', component: () => import(`@/pages/main/update`)},
        {path: '/login', component: () => import(`@/pages/main/login`)},
        {path: '/logout', component: () => import(`@/pages/main/logout`)},
        {
            path: '/main/password', component: () => import(`@/pages/main/layout_blank`),
            children: [
                {path: '', component: () => import(`@/pages/main/password`)},
            ]
        },
        {
            path: '/main', component: () => import(`@/pages/main/layout_admin`),
            children: [
                {path: '', redirect: '/main/appoint/index'},
                {path: 'dashboard', component: () => import(`@/pages/dashboard`)},

                {path: 'appoint/index', component: () => import(`@/pages/appoint/index`)},
                {path: 'appoint/form', component: () => import(`@/pages/appoint/form`)},
                {path: 'appoint/scanIdCard', component: () => import(`@/pages/appoint/scanIdCard`)},

                {path: 'consult/index', component: () => import(`@/pages/consult/index`)},
                {path: 'consult/form', component: () => import(`@/pages/consult/form`)},

                {path: 'lianhua/index', component: () => import(`@/pages/lianhua/index`)},
                {path: 'lianhua/form', component: () => import(`@/pages/lianhua/form`)},

                {path: 'shihua/index', component: () => import(`@/pages/shihua/appoint/index`)},
                {path: 'shihua/package', component: () => import(`@/pages/shihua/package/index`)},
                {path: 'shihua/package-custom', component: () => import(`@/pages/shihua/package-custom/index`)},
                {path: 'shihua/scheduling', component: () => import(`@/pages/shihua/scheduling/scheduling`)},
                {path: 'shihua/scheduling/form', name: 'enterprise-shihua-scheduling-form', component: () => import(`@/pages/shihua/scheduling/scheduling_form`)},

                {path: 'queue', component: () => import(`@/pages/queue`)},
                {path: 'queue/room', component: () => import(`@/pages/queue/room`)},
                {path: 'queue/daping', component: () => import(`@/pages/queue/daping`)},

                {path: 'wechat/qrcode', component: () => import(`@/pages/wechat/qrcode`)},
                {path: 'wechat/notice', component: () => import(`@/pages/wechat/notice`)},
                {path: 'wechat/alert', component: () => import(`@/pages/wechat/alert`)},
                {path: 'wechat/ui', component: () => import(`@/pages/wechat/ui`)},

                {path: 'data/export', component: () => import(`@/pages/data/export`)},

                {path: 'worksheet', component: () => import(`@/pages/worksheet`)},
                {path: 'worksheet/calendar', component: () => import(`@/pages/worksheet/calendar`)},
                {path: 'worksheet/form', component: () => import(`@/pages/worksheet/worksheet_form`)},
                {path: 'worksheet/special', component: () => import(`@/pages/worksheet/special`)},
                {path: 'worksheet/overtime', component: () => import(`@/pages/worksheet/overtime`)},

                {path: 'record/todo', component: () => import(`@/pages/record/todo`)},
                {path: 'record/index', component: () => import(`@/pages/record/index`)},
                {path: 'record/form', component: () => import(`@/pages/record/form`)},

                {path: 'settings/hospital', component: () => import(`@/pages/settings/hospital`)},
                {path: 'settings/type', component: () => import(`@/pages/settings/type`)},
                {path: 'settings/department', component: () => import(`@/pages/settings/department`)},
                {path: 'settings/doctor', component: () => import(`@/pages/settings/doctor`)},
                {path: 'settings/users', component: () => import(`@/pages/settings/users`)},
                {path: 'settings/menu', component: () => import(`@/pages/settings/menu`)},

                {path: 'statistics', component: () => import(`@/pages/statistics`)},

                {path: 'oplog', component: () => import(`@/pages/oplog`)},
                {path: '*', component: () => import(`@/pages/main/notFound`)}
            ]
        },
        {path: '/daping/dating', component: () => import(`@/pages/daping/dating`)},
        {path: '/daping/zhenshi', component: () => import(`@/pages/daping/zhenshi`)},
        {path: '*', component: () => import(`@/pages/main/notFound`)}
    ]
})

export default router
