<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'app',
        methods: {
            async checkUpdate() {
                const resp = await this.$http.get('/version/guiapp')
                //console.log(resp.data.version)
                if (resp.data.version && resp.data.version != this.$pkg.version) {
                    const {shell} = require('electron')
                    const {dialog} = require('@electron/remote')
                    dialog.showMessageBox({
                        type: 'info',
                        title: '提示信息',
                        message: '发现新版本，请及时下载更新！',
                        buttons: ['确定', '取消']
                    }).then((e) => {
                        if (e.response === 0)
                            shell.openExternal(resp.data.url)
                    })
                }
            }
        },
        mounted() {
            setTimeout(() => {
                this.checkUpdate()
            }, 1000)
        }
    }
</script>

<style lang="scss">
    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        height: 100%;
    }

    .el-container {
        height: 100%;
    }

    .el-message {
        min-width: 100px !important;
    }
    .el-submenu__title i {
        color: white !important;
    }
    .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
        /*border-bottom: 3px solid white !important;*/
        /*background-color: red;*/
        /*color: #303133;*/
    }

    /*.el-calendar-table:not(.is-range) td.next, .el-calendar-table:not(.is-range) td.prev{*/
    /*  pointer-events: none;*/
    /*}*/
    .todo {
        padding: 30px;
        color: red;
    }

    .table-page {
        display: flex;
        flex-direction: column;
        /*justify-content: flex-start;*/
        /*align-items: flex-start;*/
        height: 100%;

        .page-header {
            padding-bottom: 20px;
        }

        .page-main {
            flex: 1;
        }

        .page-footer {
            text-align: center;
            padding-top: 20px;
        }
    }
</style>
