import axios from 'axios'
// import router from '@/router'

const DEV_BASE_URL = '/api'
// const DEV_BASE_URL = 'https://mingde.mriabc.com/api'
const PRO_BASE_URL = 'https://mingde.mriabc.com/api'

axios.defaults.baseURL =
    process.env.NODE_ENV === 'production' ? PRO_BASE_URL : DEV_BASE_URL

axios.defaults.timeout = 50000

// http request 拦截器
axios.interceptors.request.use(
    config => {
        // 判断是否存在token，如果存在的话，则每个http header都加上token
        let token = localStorage.getItem('token')
        if (token) {
            // config.headers.Authorization = `Bearer ${token}`
            config.headers.Authorization = `${token}`
            // config.headers['Content-Type'] = 'application/json;charset=UTF-8';
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

// http response 拦截器
axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 返回 401 清除token信息并跳转到登录页面
                    // store.commit(types.LOGOUT);
                    // router.replace({
                    //     path: '/login',
                    //     query: {
                    //         redirect: router.currentRoute.fullPath
                    //     }
                    // })
                    // break
                    return Promise.reject(new Error(`登陆状态错误： ${error.response.status}`))
                case 403:
                    return Promise.reject(new Error(`权限错误： ${error.response.status}`))
                case 404:
                    return Promise.reject(new Error(`服务端错误： ${error.response.status}`))
                case 500:
                    return Promise.reject(new Error(`服务端错误： ${error.response.status}`))
            }
        }
        // 返回接口返回的错误信息
        return Promise.reject(new Error('未知错误'))
    }
)

export default axios
