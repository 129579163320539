<template>
    <el-select :value="value" @input="$emit('input',$event)"
               @change="$emit('change', $event)"
               placeholder="请选择院区" :size="size" clearable>
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
</template>
<script>
    export default {
        name: "selectHospital",
        props: {
            value: {
                type: [String, Number,]
            },
            size: {
                default: 'small'
            }
        },
        data() {
            return {
                options:[]
            }
        },
        methods: {
            async getData() {
                const hl = await this.$http.get('/mingde/hospital/')
                this.options = hl.data.data
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>
<style lang="scss" scoped>
</style>
